import React, { useCallback, useEffect, useState } from 'react';
import { ICentre, IGeopoint } from '@mayfield/sanity';
import { graphql, useStaticQuery } from 'gatsby';
import cn from 'classnames';
import {
  useBreakpoints,
  useIntersectionAnimation
} from '@mayfield/common/hooks';
import {
  type IFilterCategory,
  SearchAndFilterMapsAutocomplete
} from '@mayfield/website/components';
import {
  distanceInKmBetweenEarthCoordinates,
  mapStateToInitials,
  stringToCamelCase
} from '@mayfield/common/utils';
import {
  Button,
  IntersectionAnimation,
  LayoutProvider
} from '@mayfield/common/components';
import { LoadScript } from '@react-google-maps/api';
import { useApp, useStaggerAnimation } from '@mayfield/website/hooks';
import CentreTile from './components/CentreTile';
import Map, { IPlace } from './components/Map';
import * as styles from './styles.module.scss';

const CentreSearch = () => {
  const {
    allSanityCentre: { nodes: centres }
  }: IQueryData = useStaticQuery(query);

  const getInitialFilterCategories = useCallback((): IFilterCategory[] => {
    const locations = new Set<string>();
    const ageGroups = new Set<string>();
    const searchRadiusKm = [10, 5, 3];

    centres.forEach((centre) => {
      locations.add(centre.address.state);

      if (centre.ageGroups.nursery) {
        ageGroups.add('Nursery');
      }

      if (centre.ageGroups.toddler) {
        ageGroups.add('Toddler');
      }

      if (centre.ageGroups.preKindy) {
        ageGroups.add('Pre-Kinder');
      }

      if (centre.ageGroups.kindergarten) {
        ageGroups.add('Kindergarten');
      }
    });

    const locationsCategory: IFilterCategory = {
      name: 'Location',
      options: Array.from(locations).map((location) => ({
        name: location,
        selected: false
      }))
    };

    const ageGroupsCategory: IFilterCategory = {
      name: 'Age',
      options: Array.from(ageGroups).map((ageGroup) => ({
        name: ageGroup,
        selected: false
      }))
    };

    const searchRadiusCategory: IFilterCategory = {
      name: 'Search Radius',
      options: searchRadiusKm.map((radius, i) => ({
        name: `${radius}km`,
        selected: i === 0
      })),
      radio: true
    };

    return [locationsCategory, ageGroupsCategory, searchRadiusCategory];
  }, [centres]);

  const [filterCategories, setFilterCategories] = useState<IFilterCategory[]>(
    getInitialFilterCategories()
  );
  const [searchedPlace, setSearchedPlace] =
    useState<google.maps.places.PlaceResult>();

  const { smallDesktop } = useBreakpoints();

  const [pageIndex, setPageIndex] = useState(0);

  const [scriptHasLoaded, setScriptHasLoaded] = useState(false);

  const apiKey = 'AIzaSyC4ZkPZk_ksi_V-GH-L-1HA00Y6Xy6EEYk'; // prod key

  const centresPerPage = smallDesktop ? 12 : 6;

  const selectedSearchRadiusKm: number = parseInt(
    filterCategories
      .find((category) => category.name === 'Search Radius')
      ?.options.find((option) => option.selected)
      ?.name?.replace('km', '') || '0'
  );

  const getFilteredCentres = () => {
    const locationFilter = filterCategories.find(
      (category) => category.name === 'Location'
    );
    const ageGroupFilter = filterCategories.find(
      (category) => category.name === 'Age'
    );

    const activeLocationFilters = locationFilter?.options.filter(
      (option) => option.selected
    );
    const activeAgeGroupFilters = ageGroupFilter?.options.filter(
      (option) => option.selected
    );

    const isLocationFilterActive = activeLocationFilters?.[0];
    const isAgeGroupFilterActive = activeAgeGroupFilters?.[0];

    const sortByTitle = (a: { title: string }, b: { title: string }) => {
      const titleA = a?.title?.toUpperCase();
      const titleB = b?.title?.toUpperCase();

      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }

      return 0;
    };

    const categoryFilteredCentres = centres.filter((centre) => {
      if (
        isLocationFilterActive &&
        !activeLocationFilters?.some(
          (option) => option.name === centre.address.state
        )
      ) {
        return false;
      }
      const centreAgeGroups: string[] = [];
      if (centre.ageGroups.nursery) {
        centreAgeGroups.push('Nursery');
      }
      if (centre.ageGroups.toddler) {
        centreAgeGroups.push('Toddler');
      }
      if (centre.ageGroups.preKindy) {
        centreAgeGroups.push('Pre-Kinder');
      }
      if (centre.ageGroups.kindergarten) {
        centreAgeGroups.push('Kindergarten');
      }

      if (
        isAgeGroupFilterActive &&
        !activeAgeGroupFilters?.some((option) =>
          centreAgeGroups.includes(option.name)
        )
      ) {
        return false;
      }

      return true;
    });

    if (!searchedPlace) {
      return categoryFilteredCentres.sort(sortByTitle);
    }

    const locationFilteredCentres = categoryFilteredCentres.filter((centre) => {
      const searchedLocationLat = searchedPlace.geometry?.location?.lat() || 0;
      const searchedLocationLng = searchedPlace.geometry?.location?.lng() || 0;
      const centreLocationLat = centre.location.lat;
      const centreLocationLng = centre.location.lng;

      const distanceKm = distanceInKmBetweenEarthCoordinates(
        searchedLocationLat,
        searchedLocationLng,
        centreLocationLat,
        centreLocationLng
      );

      if (distanceKm <= selectedSearchRadiusKm) {
        return true;
      } else {
        return false;
      }
    });

    return locationFilteredCentres;
  };

  const filteredCentres = getFilteredCentres();

  const centresToDisplay = filteredCentres.slice(
    pageIndex * centresPerPage,
    (pageIndex + 1) * centresPerPage
  );

  const totalPages = Math.ceil(filteredCentres.length / centresPerPage);
  const isLastPage = pageIndex === totalPages - 1;

  const locations: IPlace[] = filteredCentres.map((centre) => {
    const {
      address: { postcode, state, streetAddress, suburb }
    } = centre;
    return {
      address: `${streetAddress}\n${suburb}, ${mapStateToInitials(
        state
      )} ${postcode}`,
      location: centre.location,
      name: centre.title,
      logo: centre.centreInfo.logo,
      slug: centre.slug
    };
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pageIndex]);

  // Ensure that when we switch from mobile to desktop,
  // or update filters,
  // the current page is valid
  useEffect(() => {
    if (!smallDesktop) return;

    const isInvalidPage = pageIndex > totalPages - 1;
    if (isInvalidPage) {
      setPageIndex(Math.max(totalPages - 1, 0));
    }
  }, [smallDesktop, totalPages, pageIndex]);

  // Query params for initial location filter
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { windowLocation } = useApp();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const regionFromQueryParams = params.get('region');

    if (regionFromQueryParams) {
      setFilterCategories((prev) =>
        prev.map((category) => {
          if (category.name === 'Location') {
            return {
              ...category,
              options: category.options.map((option) => ({
                ...option,
                selected:
                  stringToCamelCase(option.name) === regionFromQueryParams
              }))
            };
          } else return category;
        })
      );
      setIsFilterOpen(true);
    } else {
      setFilterCategories(getInitialFilterCategories());
      setIsFilterOpen(false);
    }
  }, [windowLocation, getInitialFilterCategories]);

  const { ref, inView } = useIntersectionAnimation();

  const { getDelayTime, staggerAnimation } = useStaggerAnimation(
    centresToDisplay.length,
    inView
  );

  const getSearchedPlaceCoordinates: () => IGeopoint | undefined = () => {
    if (!searchedPlace) return undefined;

    return {
      lat: searchedPlace?.geometry?.location?.lat() || 0,
      lng: searchedPlace?.geometry?.location?.lng() || 0
    };
  };

  return (
    <LoadScript
      googleMapsApiKey={apiKey}
      libraries={['places', 'geometry']}
      onLoad={() => setScriptHasLoaded(true)}
    >
      <div className={styles.container}>
        <LayoutProvider maxWidth padding grid>
          <div className={styles.titleAndSearch}>
            <IntersectionAnimation delay={200}>
              <h2 className={cn('h1', styles.title)}>Mayfield Locations</h2>
            </IntersectionAnimation>

            <IntersectionAnimation delay={300}>
              <SearchAndFilterMapsAutocomplete
                filterCategories={filterCategories}
                setFilterCategories={setFilterCategories}
                setSearchedPlace={setSearchedPlace}
                className={styles.searchAndFilter}
                themeColor="grass"
                placeholder="Enter Postcode or Suburb"
                isFilterOpen={isFilterOpen}
              />
            </IntersectionAnimation>
          </div>

          {smallDesktop && (
            <div className={styles.mapContainer}>
              <IntersectionAnimation animation="fade">
                <Map
                  locations={locations}
                  scriptHasLoaded={scriptHasLoaded}
                  centerCoordinates={getSearchedPlaceCoordinates()}
                  searchRadius={selectedSearchRadiusKm}
                />
              </IntersectionAnimation>
            </div>
          )}

          <div className={styles.searchResults} ref={ref}>
            <LayoutProvider grid>
              {centresToDisplay.map((centre, i) => (
                <IntersectionAnimation
                  animation={staggerAnimation ? 'fadeUp' : 'none'}
                  delay={getDelayTime(i)}
                  key={centre.slug.current}
                  className={styles.centreTile}
                  trigger={inView}
                >
                  <CentreTile {...centre} />
                </IntersectionAnimation>
              ))}

              {!centresToDisplay?.[0] && (
                <p className={cn('h3', styles.noResultsText)}>
                  Sorry, currently no centres match your search!
                </p>
              )}

              {centresToDisplay?.[0] && (
                <div className={styles.pageNavigation}>
                  <div className={styles.controls}>
                    <Button
                      iconLeft="arrowLeft"
                      variant="text"
                      color="grass"
                      disabled={pageIndex === 0}
                      onClick={() => setPageIndex((prev) => prev - 1)}
                    >
                      Previous Page
                    </Button>

                    <Button
                      iconRight="arrowRight"
                      variant="text"
                      color="grass"
                      disabled={isLastPage}
                      onClick={() => setPageIndex((prev) => prev + 1)}
                    >
                      Next Page
                    </Button>
                  </div>
                  <div className={cn('caption')}>
                    Showing {pageIndex * centresPerPage + 1} -{' '}
                    {pageIndex * centresPerPage +
                      1 +
                      (centresToDisplay.length - 1)}{' '}
                    of {filteredCentres.length} results
                  </div>
                </div>
              )}
            </LayoutProvider>
          </div>
        </LayoutProvider>
      </div>
    </LoadScript>
  );
};

export default CentreSearch;

/**
 * GraphQL ====================================================================
 */

interface IQueryData {
  allSanityCentre: {
    nodes: ICentre[];
  };
}

const query = graphql`
  query {
    allSanityCentre {
      nodes {
        title

        ageGroups {
          toddler
          preKindy
          nursery
          kindergarten
        }

        centreInfo {
          logo {
            asset {
              gatsbyImageData(placeholder: BLURRED)
              url
            }
          }
        }

        location {
          lat
          lng
        }

        address {
          streetAddress
          suburb
          postcode
          state
        }

        atfImage {
          desktopImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          altText
        }

        slug {
          current
        }
      }
    }
  }
`;
