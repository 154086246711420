import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cn from 'classnames';
import {
  LayoutProvider,
  SVG,
  UniversalLink
} from '@mayfield/common/components';
import { IFooter } from '@mayfield/sanity';
import * as styles from './styles.module.scss';
import DesktopLetters from './components/DesktopLetters';

const Footer = () => {
  const {
    sanitySettings: { footer }
  }: IQueryResults = useStaticQuery(query);
  const { internalLinks, socialLinks, tagline } = footer;

  return (
    <div className={styles.container}>
      <LayoutProvider grid maxWidth padding>
        <h2 className={cn('h1', styles.footerText)}>{tagline}</h2>

        <nav className={cn(styles.nav, styles.internalLinks)}>
          <h3 className={cn(`caption`, styles.listHeader)}>Links</h3>
          <ul>
            {internalLinks.map((link, i) => (
              <li key={i} className={styles.linkItem}>
                <UniversalLink variant="text" link={link} />
              </li>
            ))}
          </ul>
        </nav>

        <nav className={cn(styles.nav, styles.socials)}>
          <h3 className={cn(`caption`, styles.listHeader)}>Connect</h3>
          <ul>
            {socialLinks.map((link, i) => (
              <li key={i} className={styles.linkItem}>
                <UniversalLink variant="text" link={link} />
              </li>
            ))}
          </ul>
        </nav>

        <div className={styles.singleColumnContent}>
          <div className={styles.letters}>
            <SVG className={styles.lettersMobile} svg="footerMobileLetters" />

            <DesktopLetters className={styles.lettersDesktop} />
          </div>

          <div className={cn('b2', styles.bottomText)}>
            <div>© Mayfield {new Date().getFullYear()}</div>

            <div className={styles.corporateLinkContainer}>
              <div className={cn('b2')}>In safe hands with</div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={cn('button-small', styles.corporateLink)}
                href="https://www.mayfieldchildcare.com.au/"
              >
                Mayfield Childcare Limited
              </a>
            </div>
          </div>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default Footer;

const query = graphql`
  query {
    sanitySettings {
      footer {
        tagline
        internalLinks {
          ...UniversalLinkWebsiteFragment
        }
        socialLinks {
          ...UniversalLinkWebsiteFragment
        }
      }
    }
  }
`;

interface IQueryResults {
  sanitySettings: {
    footer: IFooter;
  };
}
