import { useEffect, useState } from 'react';

const useStaggerAnimation = (itemsToDisplay: number, inView: boolean) => {
  const DELAY_STAGGER_MS = 100;

  const [staggerAnimation, setStaggerAnimation] = useState(true);

  const getDelayTime = (index: number) => {
    if (staggerAnimation) {
      return 400 + index * DELAY_STAGGER_MS;
    }

    return 0;
  };

  useEffect(() => {
    if (!inView) return;

    const timeUntilAllTilesAreVisibleMs =
      400 + itemsToDisplay * DELAY_STAGGER_MS;

    setTimeout(() => {
      setStaggerAnimation(false);
    }, timeUntilAllTilesAreVisibleMs);
  }, [inView, itemsToDisplay]);

  return { staggerAnimation, getDelayTime };
};

export default useStaggerAnimation;
