import React from 'react';
import cn from 'classnames';
import {
  IntersectionAnimation,
  LayoutProvider,
  PaginationControls
} from '@mayfield/common/components';
import { graphql, useStaticQuery } from 'gatsby';
import { useEmbla } from '@mayfield/website/hooks';
import {
  useBreakpoints,
  useIntersectionAnimation
} from '@mayfield/common/hooks';
import { EmblaOptionsType } from 'embla-carousel-react';
import FeaturedArticleTile, {
  IFeaturedArticleTileProps
} from './components/FeaturedArticleTile';
import * as styles from './styles.module.scss';

interface IQueryResults {
  sanityFeaturedArticles: {
    featuredArticles: IFeaturedArticleTileProps[];
  };
}

const FeaturedArticles = () => {
  const {
    sanityFeaturedArticles: { featuredArticles }
  } = useStaticQuery(query) as IQueryResults;

  const { tablet } = useBreakpoints();

  const emblaOptions: EmblaOptionsType = {
    slidesToScroll: tablet ? 2 : 1
  };

  const numberOfSlides = tablet
    ? featuredArticles.length / 2
    : featuredArticles.length;

  const {
    activeSlideIndex,
    emblaRef,
    scrollNext,
    scrollPrev,
    setActiveSlideIndex
  } = useEmbla(numberOfSlides, emblaOptions);

  const { inView, ref } = useIntersectionAnimation();

  const useCarousel = featuredArticles.length > 2;

  return (
    <div ref={ref} className={styles.container}>
      <LayoutProvider maxWidth padding>
        <IntersectionAnimation delay={200} trigger={inView}>
          <h2 className={cn('h2', styles.title)}>Featured Articles</h2>
        </IntersectionAnimation>

        <IntersectionAnimation trigger={inView} delay={400}>
          {useCarousel && (
            <div className={styles.carouselContainer} ref={emblaRef}>
              <div className={styles.carouselContent}>
                {featuredArticles.map(
                  ({ image, title, date, description, slug }, i) => (
                    <FeaturedArticleTile
                      key={i}
                      className={styles.slide}
                      image={image}
                      title={title}
                      date={date}
                      description={description}
                      slug={slug}
                    />
                  )
                )}
              </div>

              <LayoutProvider grid maxWidth padding>
                <div className={styles.paginationContainer}>
                  <PaginationControls
                    activeIndex={activeSlideIndex}
                    numberOfItems={numberOfSlides}
                    scrollNext={scrollNext}
                    scrollPrev={scrollPrev}
                    setActiveIndex={setActiveSlideIndex}
                    className={styles.paginationControls}
                    color="var(--color-buff)"
                  />
                </div>
              </LayoutProvider>
            </div>
          )}

          {!useCarousel && (
            <div className={styles.nonCarouselContent}>
              {featuredArticles.map(
                ({ image, title, date, description, slug }, i) => (
                  <FeaturedArticleTile
                    key={i}
                    image={image}
                    title={title}
                    date={date}
                    description={description}
                    slug={slug}
                  />
                )
              )}
            </div>
          )}
        </IntersectionAnimation>
      </LayoutProvider>
    </div>
  );
};

export default FeaturedArticles;

const query = graphql`
  query FeaturedArticlesQuery {
    sanityFeaturedArticles {
      featuredArticles {
        title
        slug {
          current
        }
        image {
          altText
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        description
        date
      }
    }
  }
`;
