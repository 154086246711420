import React, { useState } from 'react';
import cn from 'classnames';
import { LayoutProvider, TextInput } from '@mayfield/common/components';
import {
  ISearchFiltersProps,
  SearchFilters
} from '@mayfield/website/components';
import { Autocomplete } from '@react-google-maps/api';
import * as styles from './styles.module.scss';

interface IFilterOption {
  name: string;
  selected: boolean;
}

export interface IFilterCategory {
  name: string;
  options: IFilterOption[];
}

interface IProps extends ISearchFiltersProps {
  setSearchedPlace: React.Dispatch<
    React.SetStateAction<google.maps.places.PlaceResult | undefined>
  >;
  className?: string;
  placeholder?: string;
}

const SearchAndFilterMapsAutocomplete = ({
  filterCategories,
  setFilterCategories,
  setSearchedPlace,
  placeholder,
  isFilterOpen,
  themeColor = 'sea',
  className
}: IProps) => {
  const [autocompleteData, setAutocompleteData] =
    useState<google.maps.places.Autocomplete>();

  const handleChange = (value: string) => {
    if (!value) {
      setSearchedPlace(undefined);
    }
  };

  return (
    <LayoutProvider grid className={cn(styles.container, className)}>
      <Autocomplete
        className={styles.autocomplete}
        types={['postal_code', 'locality']}
        options={{
          componentRestrictions: { country: 'au' }
        }}
        onLoad={(autocomplete) => setAutocompleteData(autocomplete)}
        onPlaceChanged={() => {
          setSearchedPlace(autocompleteData?.getPlace());
        }}
      >
        <TextInput
          placeholder={placeholder}
          themeColor={themeColor}
          onChange={handleChange}
        />
      </Autocomplete>

      <SearchFilters
        filterCategories={filterCategories}
        setFilterCategories={setFilterCategories}
        isFilterOpen={isFilterOpen}
        themeColor={themeColor}
      />
    </LayoutProvider>
  );
};

export default SearchAndFilterMapsAutocomplete;
