exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-templates-book-a-tour-index-tsx": () => import("./../../../src/templates/bookATour/index.tsx" /* webpackChunkName: "component---src-templates-book-a-tour-index-tsx" */),
  "component---src-templates-centre-index-tsx": () => import("./../../../src/templates/centre/index.tsx" /* webpackChunkName: "component---src-templates-centre-index-tsx" */),
  "component---src-templates-job-listing-index-tsx": () => import("./../../../src/templates/jobListing/index.tsx" /* webpackChunkName: "component---src-templates-job-listing-index-tsx" */),
  "component---src-templates-news-article-index-tsx": () => import("./../../../src/templates/newsArticle/index.tsx" /* webpackChunkName: "component---src-templates-news-article-index-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../../../src/templates/page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */)
}

