import { useContext } from 'react';
import {
  CCSResultsContext,
  ICCSResultsContext
} from '@mayfield/website/context';

export const useCCSResults = (): ICCSResultsContext =>
  useContext(CCSResultsContext);

export default useCCSResults;
