import React from 'react';
import { ICentre } from '@mayfield/sanity';
import { Button } from '@mayfield/common/components';
import cn from 'classnames';
import { mapStateToInitials } from '@mayfield/common/utils';
import { useApp } from '@mayfield/website/hooks';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import * as styles from './styles.module.scss';

const CentreTile = ({
  title,
  atfImage,
  address,
  centreInfo: { logo },
  slug
}: ICentre) => {
  const { setIsBookATourSidebarOpen, setBookATourSelectedCentre } = useApp();

  const formattedAddress = `${address.streetAddress}\n${
    address.suburb
  }, ${mapStateToInitials(address.state)} ${address.postcode}`;

  const handleTileClick = (e: React.MouseEvent) => {
    if (
      e.target instanceof HTMLAnchorElement ||
      e.target instanceof HTMLButtonElement
    )
      return;

    const selection = window.getSelection()?.toString().trim();
    if (selection) return;

    navigate(`/centres/${slug.current}`);
  };

  const handleBookATourClick = () => {
    setIsBookATourSidebarOpen(true);
    setBookATourSelectedCentre({
      label: title,
      value: title
    });
  };

  return (
    <div
      className={styles.container}
      onClick={(e: React.MouseEvent) => handleTileClick(e)}
    >
      <div className={styles.imageContainer}>
        <GatsbyImage
          alt={atfImage.altText || ''}
          style={{ position: 'absolute', inset: 0 }}
          image={atfImage.desktopImage.asset.gatsbyImageData}
        />
      </div>

      <div className={styles.titleAndLogo}>
        <h3 className={cn('b1', styles.title)}>{title}</h3>
        <div className={styles.logo}>
          <GatsbyImage alt="Mayfield logo" image={logo.asset.gatsbyImageData} />
        </div>
      </div>

      <div className={cn('b2', styles.address)}>{formattedAddress}</div>

      <div className={styles.buttons}>
        <Button fluid color="grass" onClick={handleBookATourClick}>
          Book a Tour
        </Button>

        <Button
          to={`/centres/${slug.current}`}
          fluid
          color="grass"
          variant="secondary"
        >
          Read More
        </Button>
      </div>
    </div>
  );
};

export default CentreTile;
