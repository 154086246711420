import React, { useEffect, useRef, useState } from 'react';
import {
  SVG,
  LayoutProvider,
  UniversalLink,
  Button
} from '@mayfield/common/components';
import { useBreakpoints, useTrapFocus } from '@mayfield/common/hooks';
import cn from 'classnames';
import { useApp } from '@mayfield/website/hooks';
import { ICentre, ISettings } from '@mayfield/sanity';
import * as disableScrollStyles from '@mayfield/common/styles/disableScroll.module.scss';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { stringToCamelCase } from '@mayfield/common/utils';
import * as styles from './styles.module.scss';
import HamburgerButton from './components/HamburgerButton';
import DropdownButton, {
  type IDropdownOption
} from './components/DropdownButton';
import MobileMenu from './components/MobileMenu';

const Header = () => {
  const [
    lastScrollYBeforeDisablingScroll,
    setLastScrollYBeforeDisablingScroll
  ] = useState(0);

  const {
    isMobileMenuOpen,
    setIsMobileMenuOpen,
    setIsBookATourSidebarOpen,
    isBookATourSidebarOpen
  } = useApp();

  const {
    sanitySettings: { header },
    allSanityCentre: { nodes: centres }
  }: IQueryResults = useStaticQuery(query);
  const { links: headerLinks } = header;

  const getDropdownOptions: () => IDropdownOption[] = () => {
    const options: IDropdownOption[] = [];

    centres.forEach((centre) => {
      const state = centre.address.state;

      const existingOption = options.find((option) => option.title === state);
      if (existingOption) {
        return;
      }

      const locationCamelCase = stringToCamelCase(state);

      options.push({
        title: state,
        to: `/find-your-centre?region=${locationCamelCase}`
      });
    });

    return options;
  };

  const regions = getDropdownOptions().map((option) => option.title);

  const containerRef = useRef<HTMLDivElement>(null);

  useTrapFocus(isMobileMenuOpen, containerRef);

  // Disable page scroll without removing scrollbar (to prevent layout shift)
  useEffect(() => {
    if (isMobileMenuOpen) {
      const scrollY = document.documentElement.scrollTop;
      setLastScrollYBeforeDisablingScroll(scrollY);
      document.body.style.top = `-${scrollY}px`;
      document.body.classList.add(disableScrollStyles.disableScroll);
    } else {
      document.body.style.top = `initial`;
      document.body.classList.remove(disableScrollStyles.disableScroll);
      document.documentElement.scrollTop = lastScrollYBeforeDisablingScroll;
      document.body.scrollTop = lastScrollYBeforeDisablingScroll;
    }
  }, [isMobileMenuOpen, lastScrollYBeforeDisablingScroll]);

  // Close mobile menu when desktop
  const { largeTablet } = useBreakpoints();
  useEffect(() => {
    if (largeTablet) {
      setIsMobileMenuOpen(false);
    }
  }, [largeTablet]);

  const handleClickBookATour = () => {
    setIsBookATourSidebarOpen((prev) => !prev);
  };

  const ctaButtonAriaLabel = isBookATourSidebarOpen ? 'Close' : 'Book a Tour';

  return (
    <>
      <div className={styles.container} ref={containerRef}>
        <LayoutProvider padding className={styles.content}>
          <Link to="/" className={styles.wordmarkLink}>
            <SVG svg="wordmark" className={styles.wordmark} />
          </Link>

          <nav className={styles.middleItems}>
            <DropdownButton
              buttonText="Find Your Centre"
              dropdownOptions={getDropdownOptions()}
              to="/find-your-centre"
            />

            <div className={styles.headerLinks}>
              {headerLinks.map((link, i) => (
                <UniversalLink link={link} key={i} variant="text" />
              ))}
            </div>
          </nav>

          <div className={styles.rightItems}>
            <Button
              ariaLabel={ctaButtonAriaLabel}
              className={styles.ctaButton}
              onClick={handleClickBookATour}
            >
              <span
                className={cn(styles.buttonContent, {
                  [styles.hidden]: isBookATourSidebarOpen
                })}
              >
                Book a Tour
              </span>

              <div
                className={cn(styles.buttonContent, styles.closeButtonContent, {
                  [styles.hidden]: !isBookATourSidebarOpen
                })}
              >
                <span>Close</span>
                <SVG svg="x" className={styles.xIcon} />
              </div>
            </Button>

            <HamburgerButton
              className={styles.hamburgerButton}
              onClick={() => setIsMobileMenuOpen((prev) => !prev)}
              isActive={isMobileMenuOpen}
            />
          </div>

          <MobileMenu
            isActive={isMobileMenuOpen}
            headerLinks={headerLinks}
            regions={regions}
          />
        </LayoutProvider>
      </div>
      <div aria-hidden className={styles.pageContentPadding} />
    </>
  );
};

export default Header;

const query = graphql`
  query {
    sanitySettings {
      header {
        links {
          ...UniversalLinkWebsiteFragment
        }
      }
    }
    allSanityCentre {
      nodes {
        address {
          state
        }
      }
    }
  }
`;

interface IQueryResults {
  sanitySettings: ISettings;
  allSanityCentre: {
    nodes: ICentre[];
  };
}
