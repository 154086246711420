import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ISeoGlobal } from '@mayfield/sanity';
import { useSiteMetadata } from '@mayfield/website/hooks';
import FacebookSEO from './Facebook';
import TwitterSEO from './Twitter';

interface IProps {
  pageTitle: string;
  description?: string;
  openGraphImageUrl?: string;
  keywords?: string[];
  pathname?: string;
}

const SEO = ({
  pageTitle,
  pathname,
  description: pageDescription,
  keywords,
  openGraphImageUrl
}: IProps) => {
  const globalSEO: ISeoGlobal = useStaticQuery(graphql`
    query {
      sanitySettings {
        seo {
          title
          tagline
          description
          image {
            asset {
              url
            }
          }
          favicon {
            asset {
              url
            }
          }
        }
      }
    }
  `).sanitySettings.seo;

  const {
    description: globalDescription,
    favicon,
    image,
    tagline,
    title: globalTitle
  } = globalSEO;

  const titleText =
    pageTitle === `Home`
      ? `${globalTitle} | ${tagline}`
      : `${pageTitle} | ${globalTitle}`;
  const descriptionText = pageDescription || globalDescription;
  const ogImageUrl = openGraphImageUrl || image.asset.url!;
  const faviconUrl = favicon.asset.url!;
  const keywordsText = keywords ? keywords.join(', ') : null;

  const meta = useSiteMetadata();

  return (
    <>
      <title>{titleText}</title>
      <link rel="icon" type="image/png" href={faviconUrl} />
      <link rel="canonical" href={`${meta.siteUrl}${pathname}`} />
      <meta name="description" content={descriptionText} />

      {keywordsText && <meta name="keywords" content={keywordsText} />}

      <FacebookSEO
        description={descriptionText}
        ogImageUrl={ogImageUrl}
        siteUrl={meta.siteUrl}
        title={titleText}
      />
      <TwitterSEO
        description={descriptionText}
        ogImageUrl={ogImageUrl}
        title={titleText}
      />
    </>
  );
};

export default SEO;
