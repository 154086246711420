import React from 'react';
import { IQuestionAnswer } from '@mayfield/sanity';
import { SVG } from '@mayfield/common/components';
import { useAccordion } from '@mayfield/common/hooks';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps extends IQuestionAnswer {
  className?: string;
}

const Accordion = ({ answer, question, className }: IProps) => {
  const { containerHeight, contentRef, isExpanded, setIsExpanded } =
    useAccordion();

  const handleClick = () => setIsExpanded((prev) => !prev);

  return (
    <div className={cn(className, { [styles.active]: isExpanded })}>
      <button onClick={handleClick} className={cn('h4', styles.button)}>
        <span>{question}</span>
        <SVG svg="chevronDown" className={styles.chevron} />
      </button>

      <div
        className={styles.accordionContent}
        style={{ height: containerHeight }}
      >
        <p ref={contentRef} className={cn('b2', styles.content)}>
          {answer}
        </p>
      </div>
    </div>
  );
};

export default Accordion;
