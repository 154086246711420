import { TState } from '@mayfield/sanity';

export const remToPx = (rem: number) => {
  if (typeof window === `undefined`) {
    return rem;
  }

  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

interface IRBG {
  red: number;
  green: number;
  blue: number;
}

export const hexToRGB: (hex: string) => IRBG = (hex) => {
  const hexWithoutHash = hex.replace(/^#/, '');

  // Convert the background color to RGB
  const red = parseInt(hexWithoutHash.substring(0, 2), 16);
  const green = parseInt(hexWithoutHash.substring(2, 4), 16);
  const blue = parseInt(hexWithoutHash.substring(4, 6), 16);

  return {
    red,
    green,
    blue
  };
};

export const capitalizeString = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const spliceFromStateArray = (array: any[], item: any) => {
  if (!array?.[0] || !array?.includes(item)) {
    return array;
  }

  const arrayClone = JSON.parse(JSON.stringify(array));

  arrayClone.splice(array.indexOf(item), 1);

  return arrayClone;
};

export const spliceFromStateArrayByProperty = (
  array: any[],
  key: string,
  value: any
) => {
  if (!array?.[0]) {
    return array;
  }

  const item = array?.find((arrayItem) => arrayItem?.[key] === value);

  if (!item) {
    return array;
  }

  return spliceFromStateArray(array, item);
};

export const spliceFromStateArrayByIndex = (array: any[], index: number) => {
  if (!array?.[0] || !array?.[index]) {
    return array;
  }

  const arrayClone = JSON.parse(JSON.stringify(array));

  arrayClone.splice(index, 1);

  return arrayClone;
};

export const getRandomIntByRange = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const shuffleArray = (array: any[]) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const sortArrayByNumberProperty = (array: any[], property: string) => {
  if (!array?.[0]?.[property]) {
    return array;
  }

  return array.sort((a, b) => {
    const itemA = a?.[property] || 9999999999;
    const itemB = b?.[property] || 9999999999;

    if (itemA < itemB) {
      return -1;
    }

    if (itemA > itemB) {
      return 1;
    }

    return 0;
  });
};

export const splitCamelCase = (word: string) =>
  word.replace(/([A-Z])/g, ` $1`).replace(/^./, (str) => str.toUpperCase());

export const getCurrentLink = (pathname: string) => pathname;

export const rotisserieCamel = (camel: string) =>
  camel.replace(/([A-Z0-9])/g, `-$1`).toLowerCase();

export const camelCaseToTitleCase = (camelCase: string) => {
  const splitToWords = camelCase.replace(/([A-Z])/g, ` $1`);
  const capitalise =
    splitToWords.charAt(0).toUpperCase() + splitToWords.slice(1);
  return capitalise;
};
export const dashToCamel = (dashStr: string) =>
  dashStr.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result as string;
      resolve(result);
    };
    reader.onerror = () => {
      reject(new Error('Error reading the file'));
    };
    reader.readAsDataURL(file);
  });
};

export const pxStringToNumber = (pxString: string) => {
  const stringWithoutPx = pxString.replace('px', '');
  const number = parseInt(stringWithoutPx);
  return number;
};

export const stringToCamelCase = (string: string) => {
  const words = string.split(' ');

  const camelCaseWords = words.map((word, i) => {
    if (i === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return camelCaseWords.join('');
};

export const bytesToMB: (bytes: number) => number = (bytes) => {
  return parseFloat((bytes / (1024 * 1024)).toFixed(2));
};

export const formatDollarAmount: (
  number: number,
  noDecimals?: boolean
) => string = (number, noDecimals) => {
  const formattedDecimals = number.toFixed(2);

  const [integerPart, decimalPart] = formattedDecimals.split('.');

  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (noDecimals) {
    return `$${integerWithCommas}`;
  }

  return `$${integerWithCommas}.${decimalPart}`;
};

export const mapStateToInitials: (state: TState) => string = (state) => {
  switch (state) {
    case 'ACT':
      return 'ACT';
    case 'New South Wales':
      return 'NSW';
    case 'Queensland':
      return 'QLD';
    case 'South Australia':
      return 'SA';
    case 'Tasmania':
      return 'TAS';
    case 'Victoria':
      return 'VIC';
    case 'Western Australia':
      return 'WA';
    case 'Northern Territory':
      return 'NT';

    default:
      return '';
  }
};

export const distanceInKmBetweenEarthCoordinates = (
  location1Lat: number,
  location1Lng: number,
  location2Lat: number,
  location2Lng: number
) => {
  const EARTH_RADIUS_KM = 6371;

  const degreesToRadians = (degrees: number) => {
    return (degrees * Math.PI) / 180;
  };

  const deltaLat = degreesToRadians(location2Lat - location1Lat);
  const deltaLng = degreesToRadians(location2Lng - location1Lng);

  const location1LatRadians = degreesToRadians(location1Lat);
  const location2LatRadians = degreesToRadians(location2Lat);

  const halfChordLengthSquared =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2) *
      Math.cos(location1LatRadians) *
      Math.cos(location2LatRadians);

  const angularDistanceRadians =
    2 *
    Math.atan2(
      Math.sqrt(halfChordLengthSquared),
      Math.sqrt(1 - halfChordLengthSquared)
    );

  return EARTH_RADIUS_KM * angularDistanceRadians;
};

export const hasCommonElement = (arr1: any[], arr2: any[]): boolean => {
  return arr1.some((element) => arr2.includes(element));
};

export const formatDateString = (dateString: string): string => {
  // dateString format: YYYY-MM-DD
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const dateObj = new Date(dateString);

  const day = dateObj.getDate();
  const month = months[dateObj.getMonth()];
  const year = dateObj.getFullYear();

  const suffix = day === 1 ? 'st' : day === 2 ? 'nd' : day === 3 ? 'rd' : 'th';

  return `${day}${suffix} ${month}, ${year}`;
};
